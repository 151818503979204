
import { defineComponent } from 'vue';
import DashboardGrid from '@/components/DashboardGrid.vue';

export default defineComponent({
  name: 'AgenciesGeneralTab',
  components: {
    DashboardGrid,
  },
});
